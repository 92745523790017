import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const url = process.env.REACT_APP_ADMIN

export default function LoginForm() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    console.table(username, password);
    axios
      .post(url, { username, password })
      .then((response) => {
        console.log(response.data);
        const accessToken = response.data.accessToken;
        localStorage.setItem("accessToken", accessToken);
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          p: 3,
        }}
      >
        <FormControl sx={{ minWidth: 300, gap: 2 }}>
          <TextField
            name="username"
            variant="outlined"
            label="Username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />

          <TextField
            name="password"
            variant="outlined"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button
            variant="contained"
            size="large"
            disabled={!username || !password}
            onClick={handleSubmit}
          >
            Login
          </Button>
        </FormControl>
      </Box>
    </Container>
  );
}
