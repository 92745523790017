import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#f5fcff",
    // border: "1px solid red",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xs")]: {
      alignItems: "center",
      marginTop: theme.spacing(20),
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(30),
      flexDirection: "row",
      alignItems: "normal",
      gap: theme.spacing(6),
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
    },
  },
  winner: {
    // border: "1px solid blue",
    backgroundColor: "#ffffff",
    textAlign: "center",
    boxShadow: "0 5px 12px rgb(0 0 0 / 0.2)",
    borderBottom: "10px solid orange",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    // justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(1),
    flex: 1,
    [theme.breakpoints.up("xs")]: {
      maxWidth: "90vw",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "30vw",
    },
  },
}));

export default function Prizes() {
  const classes = useStyles();

  return (
    <Box className={classes.bg}>
      <Box className={classes.winner}>
        <EmojiEventsIcon color="success" fontSize="large" />
        <Typography variant="h5" fontWeight="bold">
          Grand Winner Gets:
        </Typography>
        <Typography>
          The Winner will be Crowned “Mr or Miss Crown 2023”, cash prize of
          N500,000.00, ipad, and other benefits. Lots and lots of gifts and
          benefits.
        </Typography>
      </Box>
      <Box className={classes.winner}>
        <MilitaryTechIcon color="success" fontSize="large" />
        <Typography variant="h5" fontWeight="bold">
          Top 5 Contestants Gets:
        </Typography>
        <Typography>
          Our Top 5 Contestants won't just shine—they'll also take home
          fantastic consolation prizes!
        </Typography>
      </Box>
    </Box>
  );
}
