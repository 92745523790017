import React from "react";
import Accordion from "./accordion";
import picture from "../images/faq.jpeg";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const accordionData = [
  {
    title: "What is the contest about?",
    content: `The contest is centered around love and partnership. The profound love a mother shares with her child
        is second to none, therefore we created this event to help parents showcase and prove to the world how much they love their child and how
        far they're willing to go for them. This contest also help portray great qualities in a child.`,
  },
  {
    title: "What are the requirement(s)?",
    content: `Children eligible for participation must fall within the age range of 0 to 10 years old, and must reside in Nigeria. For further details, kindly refer to our Terms & Conditions.`,
  },
  {
    title: "Why do I pay to vote?",
    content: `Through our program, a portion of your payment contributes significantly to improving the lives of underprivileged and homeless children by providing them with essential nourishment and support.`,
  },
  {
    title: "How do I register?",
    content: `Please click on "Register" and accurately fill in the required information. Following this, your child's Account Manager will reach out to you via phone call to provide further details and brief you about the competition.`,
  },
];

export default function Index() {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            margin: 2,
            borderRadius: "5rem 0 5rem 0",
            // border: "2px solid red",
          }}
        >
          <img src={picture} width="700" alt="" />
        </Box>
        <Box>
          <Typography variant="h5" fontWeight="bold" textAlign="center">
            {" "}
            Frequently Asked Questions{" "}
          </Typography>
          <Box>
            {accordionData.map(({ title, content }, index) => (
              <Accordion title={title} content={content} key={index} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
