import React, { useState } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { titleCase } from "../helpers/formatter";
const url = process.env.REACT_APP_ADMIN_UPDATE_VOTE;
// const url = "http://127.0.0.1:9090/api/admin/add-vote"

export default function Dashboard() {
  const [id, setID] = useState("");
  const [amount, setAmount] = useState("");
  const [voter, setVoter] = useState("Anonymous");
  const [disabled, setDisabled] = useState(false);
  // const [success, setSuccess] = useState();

  function handleSubmit() {
    setDisabled(true);

    try {
      axios
        .post(url, {
          id,
          time: Date.now().toString(),
          vote: Math.floor(amount / 50),
          voter: titleCase(voter),
        })
        .then(({ statusText }) => {
        //   console.log(response);
          if (statusText === "OK") {
            alert("SUCCESS!");
            setTimeout(() => {
              window.location.reload();
              setDisabled(false);
            }, 500);
          }
        })
        .catch((err) => {
          //handle error
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          p: 3,
        }}
      >
        <Typography gutterBottom textAlign="center">
          Add votes:
        </Typography>
        <FormControl sx={{ minWidth: 300, gap: 2 }}>
          <TextField
            name="id"
            variant="outlined"
            label="ID"
            placeholder="ID"
            value={id}
            onChange={(e) => {
              setID(e.target.value);
            }}
          />
          <TextField
            name="amount"
            variant="outlined"
            type="number"
            label="amount"
            placeholder="amount"
            value={amount}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setAmount(value);
            }}
          />
          <TextField
            name="voter"
            variant="outlined"
            label="voter"
            placeholder="voter"
            value={voter}
            onChange={(e) => {
              setVoter(e.target.value);
            }}
          />
          <Button
            variant="contained"
            size="large"
            disabled={disabled}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </FormControl>
      </Box>
    </Container>
  );
}
