// import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import "../styles/heroAnimation.css";
import hero from "./pictures/hero.jpeg";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#fff",
    textAlign: "center",
    paddingTop: theme.spacing(3),
    // border: "5px solid blue",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imgbg: {
    position: "absolute",
    top: "27vh",
    overflow: "visible",
    backgroundImage: `url(${hero})`,
    backgroundSize: "cover",
    width: "80vw",
    height: "400px",
    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      top: "35vh",
      backgroundPosition: "center",
      // border: "5px solid gray",
    },
  },
  btnContainer: {
    display: "flex",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function Hero() {
  const classes = useStyles();

  return (
    <div className="fluidContainer">
      <div className="background">
        <Box className={classes.container}>
          <Box>
            <Typography
              variant="h1"
              fontWeight="bold"
              sx={{
                fontSize: {
                  xs: 25,
                  sm: 35,
                },
              }}
            >
              How cute <br /> is your child?
            </Typography>
            <Typography
              sx={{
                padding: 2,
                fontSize: {
                  xs: 15,
                  sm: 25,
                },
              }}
            >
              We aim to inspire Nigerian kids and strengthen family bonds at home 💝
            </Typography>
          </Box>
          <Box className={classes.btnContainer}>
            <Button
              sx={{ fontWeight: "bold" }}
              variant="contained"
              href="/register"
            >
              Register
            </Button>
            <Button
              sx={{ fontWeight: "bold", color: "#fff" }}
              variant="outlined"
              href="/about"
            >
              Learn more
            </Button>
          </Box>
          <Box className={classes.imgbg}></Box>
        </Box>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
      </div>
    </div>
  );
}
