import image1 from './pictures/image1.jpg';
import image2 from './pictures/image2.jpg';
import image3 from './pictures/image3.jpg';
import image4 from './pictures/image4.jpg';
import image5 from './pictures/image5.jpg';
import image6 from './pictures/image6.jpg';
import image7 from './pictures/image7.jpg';
import image8 from './pictures/image8.jpg';
import image9 from './pictures/children.jpeg';
import image10 from './pictures/image10.jpeg';



export const itemData = [
    {
      img: image1,
      title: 'Bed',
    },
    {
      img: image2,
      title: 'Books',
    },
    {
      img: image3,
      title: 'Sink',
    },
    {
      img: image4,
      title: 'Kitchen',
    },
    {
      img: image5,
      title: 'Blinds',
    },
    {
      img: image6,
      title: 'Chairs',
    },
    {
      img: image7,
      title: 'Laptop',
    },
    {
        img: image8,
        title: 'Laptop2',
    },
    {
        img: image9,
        title: 'Laptop3',
    },
    {
        img: image10,
        title: 'Laptop4',
    }
  ];
  

