import React from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function StatusAlert({ snapbar, snapbarHandleClose }) {
  return (
    <Snackbar
      open={snapbar.open}
      onClose={snapbarHandleClose}
      autoHideDuration={3000}
    >
      <Alert
        onClose={snapbarHandleClose}
        severity={snapbar.success ? "success" : "error"}
        sx={{ width: "100%" }}
      >
        {snapbar.success
          ? "Registration was successful"
          : "Registration failed - please try again"}
      </Alert>
    </Snackbar>
  );
}
