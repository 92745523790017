import React from 'react';
import { usePaystackPayment } from 'react-paystack';
import Button from '@mui/material/Button';
const url = process.env.REACT_APP_PS_KEY
// const url = "///"

export default function PaystackAuth({ handlePaymentSuccess, handlePaymentFail, vote, id, disabled }) {
    const config = {
        reference: (new Date()).getTime().toString(),
        email: `${id}-${Date.now().toString()}@gmail.com`, 
        publicKey: url,
    };

    const onSuccess = (reference) => {
        console.log(reference);
        handlePaymentSuccess();
    };
    
    const onClose = () => {
        handlePaymentFail();
        console.log('payment failed');
    };

    const initializePayment = usePaystackPayment({...config, amount: vote*5000});

    return (
        <Button
            variant="contained"
            disabled={disabled}
            sx={{fontWeight: 'bold'}}
            size="large"
            onClick={()=>{
                initializePayment(onSuccess, onClose)
            }}>
            VOTE
        </Button>
    )
}
