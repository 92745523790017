import React, { useState } from 'react';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import axios from 'axios';
const url = process.env.REACT_APP_API_UPDATE_PICTURE;
// const url = "http://localhost:8080/api/user/update-picture"

export default function UpdatePhoto() {
    const [id, setId] = useState("");
    const [image, setImage] = useState("");

    function handleSubmit(){
        console.log(id, image)
        const formData = new FormData();
        formData.append('user', JSON.stringify({id}))
        formData.append('image', image, 'image');
        
        axios.post(url, formData)
            .then((response) => {
                console.log(response)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <Box sx={{marginTop:12}}>
            <form onSubmit={handleSubmit}>
                <TextField
                    name="id"
                    onChange={(e)=>{
                        setId(e.target.value)
                    }}
                    value={id}
                    label="ID"
                />

                <Button 
                    variant="contained" 
                    component="label" 
                    endIcon={<PhotoCamera/>}
                    size="small"
                    sx={{
                        fontWeight: "fontWeightBold",
                        backgroundColor:"primary.light",
                        ":hover": {
                            backgroundColor: "primary.main"
                        }
                    }}>
                    Upload
                    <input
                        hidden 
                        type='file' 
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setImage(file);
                        }}
                    />
                </Button>
                <Button onClick={handleSubmit}>SUBMIT</Button>
            </form>
        </Box>
    )
};
