import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import { Box } from "@mui/material";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Loader() {
  const properties = {
    loading: true,
    color: "#2094d2",
    speedMultiplier: 2,
    css: override,
    size: 100,
  };

  return (
    <Box
      sx={{ height: "100vh", zIndex: 3, display: "flex", alignItems: "center" }}
    >
      <PuffLoader {...properties} />
    </Box>
  );
}
