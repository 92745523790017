// import { styled } from '@material-ui/core';
import createTheme from '@mui/material/styles/createTheme';

export const theme = createTheme({
    palette: {
        mode: "light",
        
        primary: {
            light: '#2094d2',
            main: '#1a77a8',
            dark: '#0b5780',
            contrastText: '#fff',
        },

        custom: {
            backgroundDark: '#262626',
            backgroundGray: '#f2f7fd',
            success: '#6f9a37',
        }
    },
    
    typography: {
        "fontFamily": '"Quicksand", "Raleway", "Helvetica", "Arial", sans-serif',
        "fontSize": 20,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        "fontWeightBold": 700,
    },
});
