import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function Action() {
  return (
    <Box
      sx={{
        backgroundColor: "#2690b1",
        padding: 2,
        color: "white",
        textAlign: "center",
      }}
    >
      <Box>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          HOW TO ENROLL
        </Typography>
        <Typography variant="h4" gutterBottom  lineHeight={.9}>
          Your Child in Kiddies Crown Contest?
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ marginBottom: 2 }}>
          To get started, kindly click the button below and fill out all the
          requested information correctly. <br/> Register now and get the chance to
          meet other awesome contestants.
        </Typography>
        <Button
          sx={{ color: "white", fontWeight: "bold" }}
          endIcon={<ArrowRightAltIcon />}
          href="/register"
        >
          Get started
        </Button>
      </Box>
    </Box>
  );
}
