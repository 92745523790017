import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export default function VoterDialog({open, updateVoter}) {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' }};
    const [anonymity, setAnonymity] = useState(false);
    const [voter, setVoter] = useState('');
    // const [openDialog, setOpenDialog] = useState(open);

    const handleClose = () => {
        console.log('Somethings up!');
    };

    const handleSubmit = () => {
        updateVoter(voter);
    };


    return (
        <Dialog onClose={handleClose} open={open} fullWidth >
            <Box sx={{display: "flex", flexDirection: "column", gap: 1, padding: 2}}>
                <DialogTitle textAlign="center">What's your name?</DialogTitle>
                <Box sx={{ display: "flex", alignItems:"center",}}>
                    <Checkbox 
                        {...label}
                        size="small"
                        onChange={()=>{
                            setAnonymity(!anonymity);
                            if (anonymity === "true"){
                                setVoter('');
                            }
                        }}
                    />
                    <Typography component="span">Tick, to keep anonymity</Typography>
                </Box>
                {!anonymity && <Box sx={{width: "100"}}>
                    <TextField
                        fullWidth
                        id="standard-basic" 
                        label="Enter Name" 
                        variant="outlined"
                        size="small"
                        value={voter}
                        helperText="The provided name will be shown under the 'RECENT' menu"
                        onChange={(e)=>{setVoter(e.target.value)}}
                    />
                </Box>}

                <Button size="large" variant="contained" disabled={!voter && !anonymity } onClick={handleSubmit}>
                    {anonymity? "Done" : "Submit"}
                </Button>
            </Box>
        </Dialog>
    );
}

VoterDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    updateVoter: PropTypes.func
};