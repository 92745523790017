import React from 'react'
import Policy from './privacy/policy'
import Action from './utilities/action'

export default function Privacy(){
    return (
        <div>
            <Policy />
            <Action />
        </div>
    )
};
