import Modal from "@mui/material/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { titleCase } from "./formatter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function RegModal({ modal, modalHandleClose }) {
  const { open, profile } = modal;

  return (
    <Modal
      open={open}
      onClose={modalHandleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" alignItems="center">
          <CheckCircleIcon color="success" fontSize="large" />
        </Box>
        <Typography
          id="modal-modal-title"
          fontWeight="fontWeightBold"
          variant="h6"
          component="h2"
          color="custom.success"
        >
          Registration Successful
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          Registration completed for{" "}
          <Typography variant="span" fontWeight="fontWeightBold">
            {titleCase(profile.name)}
          </Typography>
          . {profile.gender === "Male" ? "His" : "Her"} unique ID is{" "}
          <Typography variant="span" fontWeight="fontWeightBold">
            {profile.id}
          </Typography>
          . Kindly note down this ID as it'll be used to authenticate your
          child's profile with us.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          {" "}
          Also, you'd be reached out on phone shortly, to inform you on all you
          need to know about Kiddies Crown Contest.
        </Typography>
        <Button variant="outlined" sx={{ fontWeight: "bold" }} href={"/"}>
          Got it!
        </Button>
      </Box>
    </Modal>
  );
}
