export function nthPosition(n) {
  return (
    [`${n}st`, `${n}nd`, `${n}rd`][((((n + 90) % 100) - 10) % 10) - 1] ||
    `${n}th`
  );
}

export function titleCase(str) {
  if (!str) return;
  
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
