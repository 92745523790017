import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: {
    xs: 200,
    md: "fit-content",
  },
  padding: 30,
  borderRadius: "20px",
  boxShadow: "0px 0px 10px 0px rgba(21, 87, 153, 0.2)",
  transition: "0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0px 0px 10px 0px rgba(21, 87, 153, 0.5)",
  },
}));

// const BoxedItem = styled(Box)(({ theme }) => ({
//   ...theme.typography.body2,
//   color: theme.palette.text.secondary,
//   height: "fit-content",
//   borderRadius: "40px",
//   border: "2px solid rgba(21, 87, 153, .2)",
//   padding: 30,
// }));

export default function ValueProp() {
  return (
    <>
      <Box sx={{ textAlign: { md: "center" } }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 2 }}
        >
          Hello 👋
        </Typography>
        <Typography gutterBottom>
          Join the Kiddies Crown Contest: Promoting Happiness and Enhancing
          Family Bonds through fun activities, and also stand a chance to win
          amazing prizes!
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        // border="2px solid black"
        sx={{
          mt: 0.5,
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          gap: { md: 2 },
        }}
      >
        <Grid item xs={12} md={2}>
          <Item elevation={6}>
            <FavoriteIcon fontSize="large" />
            <Typography fontWeight="bold" variant="h6" my={0.8}>
              Experience
            </Typography>
            <Typography>
              The Kiddies Crown Contest fosters family bonding through fun
              activities, creating joy and stronger parent-child relationships.
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={2}>
          <Item elevation={6}>
            <EmojiEventsIcon fontSize="large" />
            <Typography fontWeight="bold" variant="h6" my={0.8}>
              Exciting Prizes
            </Typography>
            <Typography>
              Compete for grand prizes: N500,000 cash, Mr/Miss Crown 2024 title,
              kid's iPads, and other exciting gifts!.
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={2}>
          <Item elevation={6}>
            <MilitaryTechIcon fontSize="large" />
            <Typography fontWeight="bold" variant="h6" my={0.8}>
              Consolation Prizes
            </Typography>
            <Typography>
              The top 5 contestants will receive fantastic consolation prizes as
              recognition and reward for their efforts.
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={2}>
          <Item elevation={6}>
            <VolunteerActivismIcon fontSize="large" />
            <Typography fontWeight="bold" variant="h6" my={0.8}>
              Voting for a Cause
            </Typography>
            <Typography>
              Participate in the contest to make a difference. Every vote
              supports the #FeedAChild Campaign, providing food for children in
              need.
            </Typography>
          </Item>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button
          variant="outlined"
          sx={{ fontWeight: "bold" }}
          href="previous-winners"
          target="_blank"
        >
          See past winners
        </Button>
      </Box>
      {/* <Box mt={3}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", mt: 2, textAlign: "center" }}
        >
          What Parents are saying
        </Typography>
        <Typography textAlign="center" gutterBottom>
          Real-time experiences of parents regarding the Kiddies Crown Contest:
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <BoxedItem>
                <FormatQuoteIcon fontSize="large" />
                <Typography gutterBottom>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the when an printer took a galley.
                </Typography>
                <Typography fontWeight="bold">
                  - Mummy Godswill (Grand Winner of contest's 1st edition)
                </Typography>
              </BoxedItem>
            </Grid>
            <Grid item xs={12} md={3}>
              <BoxedItem>
                <FormatQuoteIcon fontSize="large" />
                <Typography gutterBottom>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the when an printer took a galley.
                </Typography>
                <Typography fontWeight="bold">
                  - Mummy Godswill (Grand Winner of contest's 1st edition)
                </Typography>
              </BoxedItem>
            </Grid>
            <Grid item xs={12} md={3}>
              <BoxedItem>
                <FormatQuoteIcon fontSize="large" />
                <Typography gutterBottom>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the when an printer took a galley.
                </Typography>
                <Typography fontWeight="bold">
                  - Mummy Godswill (Grand Winner of contest's 1st edition)
                </Typography>
              </BoxedItem>
            </Grid>
          </Grid>
          <Button sx={{ fontWeight: "bold" }} fontWeight="bold" variant="text">
            See more
          </Button>
        </Box>
      </Box> */}
    </>
  );
}
