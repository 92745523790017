import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Action from "../utilities/action";
import  { makeStyles } from '@mui/styles';
import React from "react";
import smiling from './pictures/smiling.jpeg';

const useStyles = makeStyles((theme) => ({
    gridItem: {
        // border: "1px solid green",
    },
    containerItem: {
        paddingY: 3,
        border: 4
    },
    span: {
        // color: "red",
        fontWeight: 700
    },
    divSpacing: {
        marginBottom: theme.spacing(3)
    },
    img: {
        // outline: '1px solid black',
        overflow: "hidden",
        width: "100%"
    },
    textTitle: {
        marginBottom: 5,
        fontWeight: 700
    }
}))

export default function KiddiesCrown(){
    const classes = useStyles();

    return (
        <Container sx={{paddingTop: 5}}>
            <Typography className={classes.textTitle} variant="h5" component="h4"> About Kiddies Crown Contest</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm ={7}>
                   <div className={classes.gridItem}>
                        <div className={classes.divSpacing}>
                            <Typography variant="h6" gutterBottom fontWeight="700">Who We Are:</Typography>
                            <Typography paragraph>
                                We’re a team of professionals under the umbrella of Kiddies Crown bounded by a 
                                common goal which is to help foster relationship between a loving parent and his/her child.
                           </Typography>
                           <Typography paragraph>
                                We’re also empathically concerned about the welfare of Nigerian children, particularly the 
                                homeless children and children in distress. We find pride in taking up the obligation to 
                                help relieve them of their distress. 
                           </Typography>
                           <Typography>
                                Kiddies Crown is a private enterprise, we’re not under any disguise of being a “charity 
                                \organisation” or an “NGO” looking to provide for children. Being a private company, we 
                                have a working business model that’s sustainable, our empathy toward humanity is what drives 
                                our selfless endeavour.
                           </Typography>
                        </div>
                        <div className={classes.divSpacing}>
                            <Typography variant="h6" gutterBottom fontWeight="700">What We're Trying to Achieve:</Typography>
                            <Typography paragraph>
                                In an average Nigerian home, family members live in isolation especially between parent and child. 
                                This is usually caused by strict work schedules of parents and other commitments. This is in turn 
                                particularly problematic to a child’s development.
                            </Typography>
                            <Typography paragraph>
                                This is the problem we’re trying to curb, to bridge the gap between parents and child, to foster 
                                their relationship. This are achieved by participation in the fun activities we usually set during 
                                stage 2 of the Kiddies Crown Contest. 
                            </Typography>
                            <Typography>
                                We’re also working towards a goal of bringing about a world, where children are not deprived of the 
                                basic human need. We believe every child deserve a good live, and to achieve this, actions must be taken.
                            </Typography>
                        </div>
                   </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <div className={classes.gridItem}>
                        <img className={classes.img} src={smiling} alt="smiling child" />
                    </div>
                </Grid>
            </Grid>
            <Action />
            <Box sx={{m: 2, display: "flex", justifyContent: "center"}}>
                <Button variant='outlined' href='/about'>Go Back</Button>
            </Box>
        </Container>
    )
}