import React from "react";
import Hero from "./home/hero";
import Prizes from "./home/prizes";
import Roadmap from "./home/roadmap";
import Faq from "./utilities/accordion/index.js";
import Video from "./helpers/video";
import Action from "./utilities/action";

export default function Home() {
  return (
    <>
      <Hero />
      <Prizes />
      <Video link="https://vimeo.com/643896506" />
      <Roadmap />
      <Faq/>
      <Action />
    </>
  );
};
