import Error from './404/error'

const NotFound = () => {
    return (
        <div> 
            <Error />
        </div>
    )
}

export default NotFound;