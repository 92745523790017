import { Box } from "@mui/system"
import ReactPlayer from "react-player/vimeo";
// import ReactPlayer from 'react-player/lazy'

export default function PlayVid({ link }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f7f3e5",
        opacity: 0.8,
        backgroundImage:
          "radial-gradient(#444cf7 0.6000000000000001px, transparent 0.6000000000000001px), radial-gradient(#444cf7 0.6000000000000001px, #f7f3e5 0.6000000000000001px)",
        backgroundSize: "24px 24px",
        backgroundPosition: "0 0,12px 12px",
      }}
    >
      <ReactPlayer url={link} controls/>
    </Box>
  );
}
