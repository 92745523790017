import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import  { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/system';
import React from 'react';
import hero from './home/pictures/hero.jpeg';
import children from './about/pictures/children.jpeg';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column"
        },
        flexWrap: "nowrap",
        justifyContent: "space-between",
        marginTop: 5,
        rowGap: theme.spacing(4),
        columnGap: theme.spacing(4)
    },
    card: {
        width: "100%",
        boxShadow: "none",
    },
    cardcontent: {
        padding: 0
    },
    titleText: {
        fontSize: 20,
        fontWeight: 700,
    },

    link: {
        fontWeight: 700,
        textDecoration:"none",
        "&:hover":{
            color: "#ff6900"
        },
        transition: ".25s"
    }
}))

export default function About(){
    const classes = useStyles();

    return (
        <Container>
            <Box sx={{textAlign:'center'}}>
                <Typography variant='h2' >About us</Typography>
                <Typography variant='h5' sx={{marginBottom: 4}}>
                    Welcome to Kiddies Crown
                </Typography>
            </Box>
            <Box className={classes.container}>
                <Card className={classes.card}>
                    <CardMedia 
                        component='img'
                        height="194"
                        image={hero}
                        alt="well..kd"
                    />
                    <CardContent className={classes.cardcontent}>
                        <Typography gutterBottom className={classes.titleText} sx={{fontWeight: 700, fontSize: 25}}>
                            About Kiddies Crown Contest
                        </Typography>
                        <Typography className={classes.bodyText} sx={{marginBottom: 2}}>
                            Kiddies Crown Contest is an event created for Nigerian families to showcase parental love and partnership 
                            towards their children. It’s a program that’s designed to help the foster relationship between a loving parent 
                            and his/her child while competing for amazing prizes.
                        </Typography>
                        <a href='/about-kiddies' className={classes.link}>READ MORE</a>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardMedia 
                        component='img'
                        height="194"
                        image={children}
                        alt="well..kd"
                    />
                    <CardContent className={classes.cardcontent}>
                        <Typography gutterBottom className={classes.titleText} sx={{fontWeight: 700, fontSize: 25}}>
                            About #FeedAChild Campaign
                        </Typography>
                        <Typography className={classes.bodyText} sx={{marginBottom: 2}}>
                            We at Kiddies Crown, are committed in helping the Nigerian children in distress. Our vision is to 
                            help contribute to a world where every child gets a fair share of life’s basic needs.                       
                         </Typography>
                        <a href='/feed-a-child' className={classes.link}>READ MORE</a>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardMedia 
                        component='img'
                        height="194"
                        image="https://educator.qodeinteractive.com/wp-content/uploads/2017/07/h2-image-5.jpg"
                        alt="well..kd"
                    />
                    <CardContent className={classes.cardcontent}>
                        <Typography gutterBottom className={classes.titleText} sx={{fontWeight: 700, fontSize: 25}}>
                            Meet Our Previous Winners
                        </Typography>
                        <Typography className={classes.bodyText} sx={{marginBottom: 2}}>
                        Meet our previous winners of the Kiddies Crown Contest, which was held on the April 
                        through May of 2022. They are indeed great personalities full of resounding love among their family.
                        It was a tough march the end. But ultimately, we're glad we were able to put
                        smiles on the faces of the winners.
                        </Typography>
                        <a href='/previous-winners' className={classes.link}>READ MORE</a>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
};

