import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

export default function Accordion({ title, content }) {
  const [toggle, seToggle] = useState(false);

  function switchToggle() {
    seToggle(!toggle);
  }

  return (
    <Box margin={2} key={title}>
      <Box
        sx={{
          borderRadius: 2,
          background: "primary.main",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={switchToggle}
      >
        <Box>
          <h4> {title} </h4>
        </Box>
        <Box> {toggle ? "-" : "+"}</Box>
      </Box>
      {toggle && <Typography margin={1}>{content}</Typography>}
    </Box>
  );
}
