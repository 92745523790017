import React from "react";
import Router from "./router";
import { theme } from "./components/styles/theme";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

export default function App() {
  console.log("Hello prod!")
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Router />
    </ThemeProvider>
  );
}
