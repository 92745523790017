import children from './pictures/omo.jpeg'
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/system';
import { Button, ImageList, ImageListItem, Typography } from '@mui/material';
import { itemData } from './imageList';
import ReactPlayer from 'react-player/vimeo'

const useStyles = makeStyles((theme) => ({
    headerTextDiv: {
        color: "#fff",
        position: 'absolute',
        paddingLeft: theme.spacing(2)
    },
    container: {
        marginTop: theme.spacing(2)
    },
    subHeading: {
        fontWeight: "700"
    },
}))


export default function FeedAChild(){
    const classes = useStyles();

    return (
        <div>
            <Box sx={{
                // border: '2px solid black',
                display: 'flex',
                alignItems: 'center',
                height: {
                    xs: "15vh",
                    md: "30vh"
                },
                overflow: 'hidden',
            }}>
                <div className={classes.headerTextDiv}>
                    <Typography sx={{fontSize: {
                        xs: "2rem",
                        sm: "3rem",
                    }}}>We Love To Give</Typography>
                </div>
                <img src={children} alt="homeless-children" width="100%" className={classes.headImage} />
            </Box>
            <Container className={classes.container}>
                <div>
                    <Typography gutterBottom className={classes.subHeading}>Our Cause:</Typography>
                    <Typography paragraph>
                        We at Kiddies Crown, are committed in helping the Nigerian children in distress. 
                        Our vision is geared towards contributing to a world where every child gets a fair 
                        share of life’s basic needs.
                    </Typography>
                    <Typography paragraph>
                        During our last outreach to the less privileged children, we visited the IDP camp located in 
                        Kuchigoro, Abuja and distributed lunch packs to all the Internally displaced person 
                        (children especially), and even villagers living around.
                    </Typography>
                    <Typography paragraph>
                        It was an awesome experience, getting to see smile on their faces as the got the package from us.
                    </Typography>
                    <Typography paragraph>
                        This time, we plan on taking it a step further. We plan on not just distributing launch packs, 
                        but also relief materials to motherless babies homes.
                    </Typography>
                </div>
                <div>
                    <Typography paragraph className={classes.subHeading}>Photos & Videos of Our Last Outreach</Typography>
                    <Box sx={{ height: 500, overflow: 'scroll' }}>
                        <ImageList variant="masonry" cols={3} gap={8}>
                            {itemData.map((item) => (
                                <ImageListItem key={item.img}>
                                <img
                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <Box sx={{
                            backgroundColor: "#f7f3e5", 
                            padding: 2,
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            gap: 2
                            }}>
                            <ReactPlayer url="https://vimeo.com/770801605" />
                            <ReactPlayer url="https://vimeo.com/770801389" />
                        </Box>
                    </Box>
                    <Box sx={{m: 2, display: "flex", justifyContent: "center"}}>
                        <Button variant='outlined' href='/about'>Go Back</Button>
                    </Box>
                </div>
            </Container>
        </div>
    )
}
