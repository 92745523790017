import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import tableCellClasses from "@mui/material/TableCell/tableCellClasses";
import { titleCase } from "../../helpers/formatter";
import { UserContext } from "../index";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Recent() {
  const data = useContext(UserContext);
  const { log } = data.user;
  // console.log(log)
  const activity = log.length > 0 ? true : false;

  return (
    <Box>
      <Box textAlign="center">
        <Typography fontWeight="fontWeightBold" textAlign="center">
          Recent Activities
        </Typography>
        <Typography variant="body2" marginBottom={2}>
          Showing the last 15 voting activities for{" "}
          <strong>{titleCase(data.user.name)}.</strong>{" "}
        </Typography>
      </Box>
      {!activity && (
        <Typography textAlign="center" color="gray">
          No activity found!
        </Typography>
      )}
      {activity && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Votes</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
                <StyledTableCell>Method</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {log.map((item, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {item.voter}
                  </StyledTableCell>
                  <StyledTableCell>{item.vote}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(Number(item.time)).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.byAdmin === false ? "Paystack" : "Bank transfer"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
